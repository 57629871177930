import { Link } from '@inertiajs/react';
import Letter from '@/Components/Letter';

const LettersList = ({ letters, level }) => {
    return (
        <div key="list-{level}">
            {letters.data.map((letter, index) => (
                <Letter key={`letterHolder-${letter.Id}-${index}`} letter={letter} level={level} />
            ))}
        </div>
    )
}

export default LettersList;